

















































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ActividadesHome extends Vue {
  private actividades: Array<any> = [];

  private created() {
    this.getActividades();
  }

  private getActividades() {
    this.$q.loading.show({
      // delay: 100, // ms
    });
    this.$axios
      .get("actividades/destacadas")
      .then((res) => {
        // console.log(res.data);
        let base = process.env.VUE_APP_BASE_URL;
        if (this.$q.screen.xs || this.$q.screen.sm) {
          if (res.data.length >= 3) {
            for (let i = 0; i < 2; i++) {
              this.actividades.push(res.data[i]);
            }
          } else {
            this.actividades = res.data;
          }
        } else {
          this.actividades = res.data;
        }
        for (let i = 0; i < this.actividades.length; i++) {
          if (this.actividades[i].foto && this.actividades[i].foto != "") {
            this.actividades[i].foto = base + this.actividades[i].foto;
          } else {
            this.actividades[i].foto = require("@/assets/logos/logo300.webp");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionAct",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private formatFechaCard(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
}
